import React from "react";
import { Grommet, Box, ResponsiveContext, Footer, Heading, Text, Anchor } from "grommet";
import Logo from "./white.svg";

const theme = {
  global: {
    colors: {
      focus: "#ffffff",
      brand: "#7d4cdb",
      "dark-0": "#0d0d0d",
      "accent-1": "#7d4cdb",
      background: {
        light: "light-1",
        dark: "dark-0"
      }
    },
    font: {
      family: "Work Sans",
      height: "20px"
    }
  }
};

const NavBar = () => {
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="center"
      background="black"
      pad={{ horizontal: "large", vertical: "medium" }}
      border="bottom"
      style={{ zIndex: "1" }}
    >
      <Box width="xxlarge" direction="row">
        <img src={Logo} height="40px" />
      </Box>
    </Box>
  );
};

const Card = props => {
  return (
    <Box
      width="large"
      align="start"
      border="all"
      background="black"
      pad={{ horizontal: "large", vertical: "large" }}
      {...props}
    />
  );
};

const App = () => {
  return (
    <Grommet theme={theme} themeMode="dark" full>
      <ResponsiveContext.Consumer>
        {size => (
          <Box fill>
            <NavBar />
            <Box
              flex
              align="center"
              justify="start"
              pad={{ horizontal: "large", vertical: "large" }}
            >
              <Card>
                <Heading level="2" margin={{bottom:"small", top: "xxsmall"}}>Om nettsiden</Heading>
                <Text>
                  Nettsiden er under utvikling, og er derfor ikke tilgjengelig.
                  Kontakt Cappelen Digital på <Anchor href="mailto:gard@cappelendigital.no">gard@cappelendigital.no.</Anchor>
                </Text>
                <Text color="dark-2" margin={{top: "medium"}}>publisert 23.03.20</Text>
              </Card>
            </Box>
            <Footer background="black" border="top">
              <Box
                flex
                align="center"
                justify="start"
                pad={{ horizontal: "small", vertical: "small" }}
              >
                <p>&copy; Cappelen Digital 2020</p>
              </Box>
            </Footer>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

export default App;